.collection_card {
    width: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: var(--primary-yellow);
    text-align: center; 
}
.collection_title {

    font-weight: bold;
    overflow-wrap: break-word;
    margin-bottom: 5px;
    font-size: 2vw;
    font-family: 'Kodchasan', sans-serif;
}

.collection_author {
    overflow-wrap: break-word;
    font-size: 1rem;
    color: black;
    margin-bottom: 10px;
    font-size: 1vw;
    font-family: 'Kodchasan', sans-serif;
}
.view_btn {
    display: inline-block;
    width: 155px;
    background-color: var(--primary-orange);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: 1vw;
    font-family: 'Kodchasan', sans-serif;
    margin-top: 10px;
}
.view_btn:hover{
    background-color: #d41f1c;
}