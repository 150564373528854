.main_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow-x: hidden;
  }
  
  .div_nav_bar {
    width: 100%;
  }
  
  .div_heading {
    width: 100%; 
    text-align: center;
  }
  
  .header {
    font-size: 5em;
    color: black;
    font-family: 'Kodchasan', sans-serif;
    margin-bottom: 10px;
  }
  
  .div_scrolling {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .subheaderAnimation {
    font-size: 2em;
    color: black;
    font-family: 'Kodchasan', sans-serif;
    margin: 0;
    line-height: 50px;
    text-align: center;
    animation: spacing 45s linear infinite;
    word-spacing: 1.0em;
    white-space: nowrap;
    display: inline-block; /* Change to inline-block */
    
}

@keyframes spacing {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-200%); 
    }
}

  .doubleSpacing {
    margin-right: 20px;
  }
  
  .div_search {
    position: relative;
    margin-top: 50px;
    width: 65%;
    display: flex;
    flex-direction: row;
  }
  
  .form_search {
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: white;
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 10px 20px;
    margin-left: 10px;
    width: 100%;
  }
  
  .div_dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
  }
  
  .div_dropdown_option {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1.5em;
    color: black;
    font-family: 'Kodchasan', sans-serif;
  }
  
  .icon_mode_recipe {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
  }
  .icon_mode_user {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  
  .dropdown_option_icon_recipe{
    margin-bottom: 2x;
    width: 25px;
    height: 25px;
  }
  .dropdown_option_icon_user{
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  .p_search_mode {
    margin: 0;
  }
  
  .form_input {
    border-radius: 0px;
    border: 1px solid transparent;
    padding: 10px 20px;
    width: 100%;
    font-size: 2em;
    color: black;
    font-family: 'Kodchasan', sans-serif;
    
  }

  .form_input:focus {
    outline: transparent;
}
  
  .div_recommended_recipes {
  }
  