.div_primary {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.div_nav_bar {
    width: 100%;
}

.div_profileimg_username_email_bio {
    display: flex;
    flex-direction: row;
    margin: 30px;
}

.img_profile_photo {
    width: 40%; 
    margin-left: 40px;
    object-fit: c;
    object-position: center; 
    border-radius: 50%;
}

.p_change_profile_image {
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 1vw;
    background-color: transparent;
    border: none;
    color: blue;
    display: flex;
    width: 40%;
    margin-left: 55px;
    justify-content: center;
    width: 60%;
    text-decoration: underline;
    
}

.div_username_email_bio {
    display: flex;
    flex-direction: column;
}

.p_username {
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 4vw;
    background-color: transparent;
    border: none;
    color: black;
    margin-left: -250px;
}

.viewUserId {
    font-size: 1vw;
    font-family: 'Kodchasan', sans-serif;
    color: black;
}

.div_bio {
    background-color: #FFC873;
    width: 400px; /* Make the input span the entire width */
    height: 200px; /* Set the height of the input box */
    padding: 10px; /* Add padding for better readability */
    margin-left: -500px;
  }

.p_bio_header{
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 3vw;
    background-color: transparent;
    border: none;
    color: black;
    margin-left: -500px;
}
 
 .p_bio {
    font-size: 16px; /* Adjust font size as needed */
    resize: vertical; /* Allow vertical resizing */
    font-family: 'Kodchasan', sans-serif;
    font-size: 1vw;
  }

.div_yourRecipes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 30%;
    height: 1%;
    width: 100%;
    margin: 15px;
}

.div_wrapper_yourCollection
.div_wrapper_yourRecipe{
    overflow-x: auto;
}
.p_yourRecipes{
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 4vw;
    background-color: transparent;
    border: none;
    color: black;
    margin: 15px;
}

.div_yourCollection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 30%;
    height: 1%;
    width: 100%;
    margin: 15px;
}

.p_yourCollection{
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 4vw;
    background-color: transparent;
    border: none;
    color: black;
    margin-left: 15px;
}
.trashCan {
    color: rgb(255, 60, 56);
    margin-right: 5px;
    font-size: 20px;
    height: 80px;
    width: 80px;
}