.div_view_recipe {
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative; 
    width: auto;
    overflow-x: scroll;
}

.div_recipe {
    display: flex;
    flex-direction: column;
    background-color: #FFC873;
    width: 80%;
    margin: auto;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    border-radius: 10px;
    height: auto;
    min-height: 100vh;
    width: 80vw;
}

.div_gen_info_recipe_photo {
    display: flex;
    flex-direction: row;
    padding: 20px;
   
}

.nav_bar {
    width: 100%;
}

.div_gen_info {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; 
}

.div_name_bkmk {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.h1_recipe_name{
    font-size: 50px;
    font-family: 'Kodchasan', sans-serif;
    padding: 0px 10px;
    margin-right: 10px;
}

.bookmark {
    height: 80px;
    width: 100px;
    margin-top: 25px;
    color: var(--primary-orange);
}

.p_author {
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    padding: 0px 10px;
}

.p_prep_time {
    font-size: 1rem;
    color: black;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    padding: 10px;
    white-space: nowrap;
    border-right: 1px solid black;
}

.p_cook_time {
    font-size: 1rem;
    color: black;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    padding: 10px;
    white-space: nowrap;
}

.p_total_time {
    font-size: 1rem;
    color: black;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    padding: 10px;
    white-space: nowrap;
    border-left: 1px solid black;
}

.div_times {
    display: flex;
    flex-direction: row;
}

.div_serve_cal {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
}

.h1_description_header {
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
    margin-bottom: 10px;
}

.p_description {
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    margin-bottom: 30px;
}

.img_recipe_photo {
    height: 600px; 
    width: auto;
    max-width: 100%; 
    padding: 20px;
    object-fit: contain;
    object-position: center;
    margin: auto;
}

.div_tags_stars {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

.div_stars_rating {
    display: flex;
    flex-direction: row;
}

.div_stars{
    display: flex;
    flex-direction: row;
}

.p_rating{
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
    margin-top:15px
}

.div_tags {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}

.h1_tags_header{ 
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
    text-align: center;
    margin-top: 5px;
}


.div_ingredients {
    display: flex;
    flex-direction: column;
}

.div_directions {
    display: flex;
    flex-direction: column;
}

.div_review {
    display: flex;
    flex-direction: column;
}

.div_rev_title_stars {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.div_review_header_subject{
    display: flex;
    flex-direction: column;
}

.div_review_stars{
    display: flex;
    flex-direction: row;
}

.div_comment_post {
    display: flex;
    flex-direction: row;
}

.p_servings {
    margin-right: 10px;
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
}

.p_calories {
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    margin-right: 10px;
}

.div_all_tags {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}
.div_all_tags > *{
    margin: 0 25px; 
}


.p_tag {
    font-size: 25px;
    font-family: 'Kodchasan', sans-serif;
    background-color: rgba(var(--primary-orange-rgb), 0.7);
    border-radius: 10px;
    text-align: center;
    border: black solid 2px;
    margin-top: -40px;
}


.div_ingredient {
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    
}

.h1_ingredients_header{
    font-size: 60px;
    font-family: 'Kodchasan', sans-serif;
    text-align: center;
}

.p_ingredient {
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
    width: 50%;
    margin: auto;
}

.div_direction {
    margin-bottom: 10px;
}

.h1_directions_header {
    font-size: 60px;
    font-family: 'Kodchasan', sans-serif;
    text-align: center;
}

.p_direction {
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
    width: 50%;
    margin: auto;
}

.h1_review_header {
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
    margin-top: -5px;
}

.input_review_header {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
}

.star {
    color: var(--primary-orange);
    margin-right: 5px;
    font-size: 20px;
    height: 80px;
    width: 80px;
}

.trashCan {
    color: rgb(255, 60, 56);
    margin-right: 5px;
    font-size: 20px;
    height: 80px;
    width: 80px;
}

.p_recipeID{
    font-size: .5vm;
    font-family: 'Kodchasan', sans-serif;
}

.star_rating {
    color: var(--primary-orange);
    margin-right: 5px;
    font-size: 20px;
    height: 50px;
    width: 50px;
}
.input_comment {
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    width: 70%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    height: 100px;
}

.button_post_review {
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: var(--primary-orange-rgb);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    background-color: rgba(var(--primary-orange-rgb), 1);
}

.button_post_review:hover {
    background-color: rgba(var(--primary-orange-rgb), 1.8);
}

.dialog_overlay {
    font-family: 'Kodchasan', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 9999;
}


.dialog_content {
    background-color: #FFC873;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
}

.dialog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-family: 'Kodchasan', sans-serif;
}

.dialog_title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.close {
    cursor: pointer;
    color: #333;
    font-size: 50px;
    justify-content: end;
    font-family: 'Kodchasan', sans-serif;
}

.close:hover {
    color: #FF5733;
}

.checkbox_container {
    margin-bottom: 20px;
}

.checkbox_label {
    font-size: 20px;
    color: #333;
    font-family: 'Kodchasan', sans-serif;
}

.checkbox_input {
    margin-right: 10px;
}

.update_button {
    padding: 10px 20px;
    background-color: #FF5733;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Kodchasan', sans-serif;
}

