.div_primary {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.div_nav_bar {
    width: 100%;
}

.div_profileimg_username_email_bio {
    display: flex;
    flex-direction: row;
    margin: 30px;
    position: relative;
}

.img_profile_photo {
    width: 80%; 
    margin-left: 15px;
    object-fit: c;
    object-position: center; 
    border-radius: 50%;
}

.p_change_profile_image {
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 1vw;
    background-color: transparent;
    border: none;
    color: blue;
    display: flex;
    width: 40%;
    margin-left: 55px;
    justify-content: center;
    width: 60%;
    text-decoration: underline;
    
}

.div_username_email_bio {
    display: flex;
    flex-direction: column;
}

.p_username {
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 4vw;
    background-color: transparent;
    border: none;
    color: black;
}

.p_bio{
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 3vw;
    background-color: transparent;
    border: none;
    color: black;
}
 
 .input_bio {
    width: 100%; /* Make the input span the entire width */
    height: 200px; /* Set the height of the input box */
    padding: 10px; /* Add padding for better readability */
    font-size: 16px; /* Adjust font size as needed */
    border: 1px solid #ccc; /* Add border for visual clarity */
    border-radius: 5px; /* Round the corners of the input box */
    resize: vertical; /* Allow vertical resizing */
    font-family: 'Kodchasan', sans-serif;
    font-size: 1vw;
  }

.div_email_changePassword {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.p_email {
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 2vw;
    background-color: transparent;
    border: none;
    color: black;
}

.button_changePassword {
    background-color: var(--primary-orange);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: 1vw;
    font-family: 'Kodchasan', sans-serif;
    margin-top: 10px;
    margin-left: 15px;
}

.div_yourRecipes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 30%;
    height: 1%;
    width: 100%;
    margin: 15px;
}

.div_wrapper_yourRecipe{
    overflow-x: auto;
}
.p_yourRecipes{
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 4vw;
    background-color: transparent;
    border: none;
    color: black;
    margin: 15px;
}

.div_wrapper_yourCollection{
    overflow-x: auto;
}

.div_yourCollection{
    display: flex;
    flex-direction: row;
   
    height: 1%;
    width: 100%;
    margin: 15px;
}

.p_yourCollection{
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 4vw;
    background-color: transparent;
    border: none;
    color: black;
    margin-left: 15px;
}
.modal {
    font-family: 'Kodchasan', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    z-index: 9999;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px; /* Adjust as needed */
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.addCollection {
    display: flex;
    flex-direction: row;   
    align-items: center; 
    margin-left: 15px; 
}

.add_btn {
    margin-top: 15px;
    margin-left: 10px; 
    font-size: 50px; 
    padding: 8px 12px; 
    background-color: transparent; /* Set background color to transparent by default */
    border: none; /* Remove border */
    transition: background-color 0.3s, color 0.3s; /* Add transition effect */
    
}

.add_btn:hover {
  
    color: #FF3C39; 
}