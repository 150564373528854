@import url('https://fonts.googleapis.com/css2?family=Kodchasan:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--secondary-yellow); 
}
:root {
  --primary-blue: #006BBB;
  --secondary-blue: #30A0E0;
  --primary-yellow: #FFC872;
  --secondary-yellow: #FFE3B3;
  --primary-orange: rgb(255, 60, 56);
  --primary-orange-rgb: 255, 60, 56;
  --error-red: #cc0000;
}

.kodchasan-extralight {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.kodchasan-light {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.kodchasan-regular {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kodchasan-medium {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kodchasan-semibold {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kodchasan-bold {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kodchasan-extralight-italic {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 200;
  font-style: italic;
}

.kodchasan-light-italic {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.kodchasan-regular-italic {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.kodchasan-medium-italic {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.kodchasan-semibold-italic {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 600;
  font-style: italic;
}

.kodchasan-bold-italic {
  font-family: 'Kodchasan', sans-serif;
  font-weight: 700;
  font-style: italic;
}
