.centered-container {
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  align-items: center;
  justify-content: center;
}

.header {
  font-family: 'Kodchasan', sans-serif;
}

.form-container {
  margin: auto;
  width: 70%;
  background-color: #FFC872;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.form-container h2 {
  text-align: center;
  font-family: 'Kodchasan', sans-serif;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container div {
  margin-bottom: 10px;
}

.form-container label {
  font-weight: bold;
  font-family: 'Kodchasan', sans-serif;
}

.form-container input,
.form-container textarea {
  width: calc(100% - 40px); /* Adjusted width to accommodate trash can icon */
  padding: 8px left, 8px right;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.tags-container,
.ingredient-container,
.direction-container {
  display: flex;
  align-items: center;
}

.delete-button-container {
  margin-left: 10px; /* Adjust as needed */
}

.div_nav_bar {
  width: 100%;
}
.form-container button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #FF3C39;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Kodchasan', sans-serif;
}

.form-container button:hover {
  background-color: #FF3C39;
}

.form-container button:hover {
  background-color: #FF3C39;
}

.error {
  font-family: 'Kodchasan', sans-serif;
  font-size: 1vm;
  color: #FF3C39;
}

.required-field {
  color: #FF3C39;
  margin-left: 5px;
}