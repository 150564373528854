

.primary_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: var(--primary-yellow);
    padding: 0 20px; 
}

.byte_bites {
    color: #000000;
    font-size: 30px;
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    background: none;
    border: none;
}

.profile_div {
    display: flex;
    align-items: center;
}

.profile_img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.div_dropdown {
    position: relative; 
}

.img_drop_down_array {
    width: 20px;
    height: 20px;
}


.div_secondary_dropdown {
    position: absolute;
    right: 0;
    margin-top: 120px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.p_option_desc {
    margin: 5px 0;
}


.no_account_div {
    display: flex;
    gap: 10px;
}


.button_text {
    margin-right: 10px;
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 25px;
    background-color: transparent;
    border: none;
    color: black;
}
.button_text:hover{
    margin-right: 10px;
    font-style: bold;
    font-family: 'Kodchasan', sans-serif;
    font-size: 25px;
    background-color: transparent;
    border: none;
    color: #FF3C39;
}


