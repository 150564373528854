

.recipe_card {
    width: 500px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: var(--primary-yellow);
   
  }
  
  .img {
    width: 300px;
    height: 300px;
    border-radius: 5px;
    margin: auto; 
    display: block;
}
  .star {
    color: var(--primary-orange);
  }
  
  .recipe_title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
  }
  
  .recipe_author {
    font-size: 1rem;
    color: black;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
  }
  
  .recipe_card_total_time_rating {
    display: flex;
    align-items: center;
    gap: 25px; 
    margin-bottom: 10px;
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
  }
  
  .total_time {
    font-size: 0.9rem;
    color: black;
    margin-right: 10px;
    font-size: 20px;
    font-family: 'Kodchasan', sans-serif;
    white-space: nowrap;
  }
  
  .rating_text {
    font-size: 1rem;
    font-weight: bold;
    color: black;
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
  }
  
  .recipe_description {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
  }
  
  .view_btn {
    display: inline-block;
    padding: 8px 16px;
    background-color: var(--primary-orange);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: 40px;
    font-family: 'Kodchasan', sans-serif;
    margin-top: 10px;
  }
  
  .view_btn:hover {
    background-color: #d41f1c;
  }
  
 